body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: scroll; /* Always show the scrollbar */
}

.title {
  text-align: center;
  font-size: 72px;
  font-weight: bold;
  margin-top: -100px;
  position: relative;
  z-index: 1;
  animation: fadeIn 2s ease-in-out, zoomOut 2s ease-in-out;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
}

.navbar-nav {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  color: #333;
  padding: 10px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
}

.nav-link:hover,
.nav-link.active {
  box-shadow: 0 0 10px #afe1af;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 0px 10px; /* Thinner navbar */
  }

  .logo-item {
    order: -1; /* Make logo appear first */
    margin-bottom: 10px;
  }

  .navbar-nav {
    justify-content: center;
    width: 100%;
    flex-wrap: wrap; /* Allow wrapping on mobile */
  }

  .nav-item {
    margin: 5px 0;
  }
  .nav-link {
    padding: 5px;
  }
}

/* Specific styles for smaller devices like iPhone SE */
@media screen and (max-width: 375px) {

  .nav-item {
    margin: 5px 0;
  }


  .nav-item:nth-child(2),
  .nav-item:nth-child(3),
  .nav-item:nth-child(5) {
    order: 0; /* Other items in the top row */
  }
}

.content {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  padding: 10px;
  text-align: center;
}

.footer p {
  margin: 0;
  font-size: 12px;
  color: #777;
  line-height: 1;
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 5px;
}

.logo:hover,
.logo:active {
  transform: scale(1.2);
  box-shadow: 0 0 10px #afe1af;
}

.gif-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  margin-top: -60px;
  animation: fadeIn 1s ease-in-out;
}

.gif {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
  object-position: center center;
}

.product-carousel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: 250px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.product-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  margin-bottom: 0px;
}

.product-title {
  font-size: 16px;
  font-weight: bold;
  margin: 6px 0;
}

.product-description {
  margin: 10px 0;
}

.buy-now-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #333;
  background-color: #fff;
  border: 2px solid #ccc;
  padding: 6px 12px;
  border-radius: 8px;
  font-weight: bold;
  transition: box-shadow 0.3s ease;
}

.buy-now-link:hover {
  box-shadow: 0 0 10px #afe1af;
}

.buy-now-link:active {
  box-shadow: none;
  transform: scale(0.95);
}

.amazon-logo {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.products-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.mission-page {
  padding: 0 20%;
}

.mission-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.blue-section {
  background-color: #c4d8ed;
}

.green-section {
  background-color: #afe1af;
}

.mission-image-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
}

.mission-description {
  flex: 1;
  margin: 0 20px;
}

.mission-description h2 {
  margin-top: 0;
}

.section-image {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
}

.rounded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .mission-page {
    padding: 0 10px;
  }

  .mission-section {
    flex-direction: column-reverse;
  }

  .mission-image-container,
  .section-image {
    width: 300px;
    height: 300px;
    margin-bottom: 10px;
  }

  .rounded-image {
    border-radius: 8px;
  }
}

.selling-points {
  padding: 0 20%;
}

.selling-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 40px;
  background-color: #f6f6f6;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out forwards;
}

.selling-description {
  flex: 1;
  margin-right: 40px;
}

.selling-description h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.selling-description p {
  margin: 20px 0;
}

.selling-image {
  flex: 1;
  max-width: 100%;
  text-align: center;
}

.selling-image img {
  width: 380px;
  height: 250px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  margin-right: 20px;
}

.selling-image:nth-child(odd) img {
  margin-right: 40px;
}

@media screen and (max-width: 768px) {
  .selling-points {
    padding: 0;
  }

  .selling-section {
    flex-direction: column-reverse;
  }

  .selling-description {
    margin-bottom: 20px;
  }

  .selling-description h2 {
    font-size: 24px;
  }

  .selling-image {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 10px;
  }

  .selling-image img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.bestseller-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffbf00;
  color: #000;
  padding: 4px 8px;
  font-weight: bold;
  z-index: 1;
  border-radius: 5px;
}

.bestseller-tag-mobile {
  display: none;
}

@media (max-width: 768px) {
  .bestseller-tag {
    display: none;
  }

  .bestseller-tag-mobile {
    display: inline-block;
    background-color: #ffbf00;
    color: #fff;
    padding: 4px 8px;
    font-weight: bold;
  }
}

.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.contact-title {
  margin-bottom: 20px;
}

.contact-info {
  margin-top: 20px;
}

.product-card.addon {
  box-shadow: none;
}

@media (max-width: 768px) {
  .product-card.addon {
    height: 380px;
  }
}

/* Customize scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Customize scrollbar for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.project-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  text-align: left;
}

.project-card img {
  max-width: 200px;
  max-height: 200px;
  margin-left: 20px;
  cursor: pointer;
}

.enlarged-image-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.enlarged-image-container img {
  max-width: 90%;
  max-height: 90%;
}

.enlarged-image-container .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
}

.extra-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff; /* Change background to white */
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 100px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.extra-card-text {
  flex: 1;
  margin-bottom: 20px;
}

.extra-card img {
  max-width: 300px; /* Increase the size of the image */
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid #ccc; /* Make it look clickable */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.extra-card img:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.enlarged-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.enlarged-view img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

.enlarged-view:before {
  content: "×";
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

/* Desktop Styles */
@media screen and (min-width: 769px) {
  .extra-card {
    flex-direction: row;
    align-items: center;
    padding-left: 30px;
  }

  .extra-card-text {
    flex: 1;
    text-align: left;
    margin-right: 20px;
  }

  .extra-card img {
    max-width: 400px; /* Make the image larger for desktop */
    margin-left: 20px;
  }

  .enlarged-view img {
    max-width: 95%; /* Increase the max-width for desktop */
    max-height: 95%; /* Increase the max-height for desktop */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
